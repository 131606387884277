import { ROUTE_URLS } from './routeUrls'

export const PROJECT_PERMISSIONS = {
  VIEW_CHART_PAGE: 'view_chart_page',
  ACTIONS_FOR_CHARTS: 'actions_for_charts',
  VIEW_TABLE_PAGE: 'view_table_page',
  ACTIONS_FOR_TABLES: 'actions_for_tables',
  VIEW_HOME_WIDGETS: 'view_home_widgets',
  EDIT_HOME_WIDGETS: 'edit_home_widgets',
  ADMIN: 'admin',
}

export const MAPPED_PAGE_PERMISSIONS = {
  [ROUTE_URLS.CHARTS]: PROJECT_PERMISSIONS.VIEW_CHART_PAGE,
  [ROUTE_URLS.TABLES]: PROJECT_PERMISSIONS.VIEW_TABLE_PAGE,
  [ROUTE_URLS.USERS]: PROJECT_PERMISSIONS.ADMIN,
  [ROUTE_URLS.PROJECT_LOGS]: PROJECT_PERMISSIONS.ADMIN,
  [ROUTE_URLS.PROJECT_SETTINGS]: PROJECT_PERMISSIONS.ADMIN,
  [ROUTE_URLS.PROJECT_USER_PERMISSIONS]: PROJECT_PERMISSIONS.ADMIN,
  [ROUTE_URLS.ACTION_GROUP_PERMISSIONS]: PROJECT_PERMISSIONS.ADMIN,
  [ROUTE_URLS.BUILD_API]: PROJECT_PERMISSIONS.ADMIN,
  [ROUTE_URLS.CHOOSE_CREATE_API_TYPE]: PROJECT_PERMISSIONS.ADMIN,
  [ROUTE_URLS.CHAT_ACTION_BUILDER]: PROJECT_PERMISSIONS.ADMIN,
}
