import { createSlice } from '@reduxjs/toolkit'

const chatActionBuilderSlice = createSlice({
  name: 'chatActionBuilder',
  initialState: {
    chatMessages: [],
    action: {},
    actionChatHashId: null,
    isThinking: false,
    hasError: false,
  },
  reducers: {
    clearChatActionBuilder: state => {
      state.chatMessages = []
      state.action = {}
      state.actionChatHashId = null
      state.isThinking = false
    },
    setChatMessages: (state, action) => {
      state.chatMessages = action.payload
    },
    addChatMessage: (state, action) => {
      state.chatMessages.push(action.payload)
    },
    setAction: (state, action) => {
      state.action = action.payload
    },
    setIsThinking: (state, action) => {
      state.isThinking = action.payload
    },
    setActionChatHashId: (state, action) => {
      state.actionChatHashId = action.payload
    },
    setHasError: (state, action) => {
      state.hasError = action.payload
    },
  },
})

export const hasMessagesSelector = state => state.chatActionBuilder.chatMessages.length > 0
export const hasCurrentActionSelector = state => Object.keys(state.chatActionBuilder.action).length > 0

export const chatActionBuilderReducerActions = chatActionBuilderSlice.actions
export const chatActionBuilderReducerSelector = {
  hasMessagesSelector,
  hasCurrentActionSelector,
}

export default chatActionBuilderSlice.reducer
