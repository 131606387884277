const LOCAL_STORAGE_KEYS = {
  USER_TOKEN: 'USER_TOKEN',
  ACTIVE_PROJECT_ID: 'ACTIVE_PROJECT_ID',
  ACTIVE_SIDE_CONTENT_TYPE: 'ACTIVE_SIDE_CONTENT_TYPE',
  DOWNLOAD_APP_ADVERT_SHOWN: 'DOWNLOAD_APP_ADVERT_SHOWN',
  ACTIVE_LANGUAGE: 'ACTIVE_LANGUAGE',
  COPIED_FIELD: 'COPIED_FIELD',
}

export const setUserTokenStorage = userToken => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.USER_TOKEN, userToken)
}
export const getUserTokenStorage = () => localStorage.getItem(LOCAL_STORAGE_KEYS.USER_TOKEN)
export const removeUserTokenStorage = () => localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_TOKEN)

export const setActiveProjectId = projectId => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_PROJECT_ID, projectId)
}
export const getActiveProjectId = () => localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_PROJECT_ID)
export const removeActiveProjectId = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.ACTIVE_PROJECT_ID)
}

export const setActiveSideContentType = value => localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_SIDE_CONTENT_TYPE, value)
export const getActiveSideContentType = () => localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_SIDE_CONTENT_TYPE)
export const removeActiveSideContentType = () => localStorage.removeItem(LOCAL_STORAGE_KEYS.ACTIVE_SIDE_CONTENT_TYPE)

export const setDownloadAppAdvertShown = value => localStorage.setItem(LOCAL_STORAGE_KEYS.DOWNLOAD_APP_ADVERT_SHOWN, value)
export const getDownloadAppAdvertShown = () => localStorage.getItem(LOCAL_STORAGE_KEYS.DOWNLOAD_APP_ADVERT_SHOWN)
export const removeDownloadAppAdvertShown = () => localStorage.removeItem(LOCAL_STORAGE_KEYS.DOWNLOAD_APP_ADVERT_SHOWN)

export const setActiveLanguage = value => localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVE_LANGUAGE, value)
export const getActiveLanguage = () => localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVE_LANGUAGE)

export const setCopiedField = field => localStorage.setItem(LOCAL_STORAGE_KEYS.COPIED_FIELD, field)
export const getCopiedField = () => localStorage.getItem(LOCAL_STORAGE_KEYS.COPIED_FIELD)
export const removeCopiedField = () => localStorage.removeItem(LOCAL_STORAGE_KEYS.COPIED_FIELD)
