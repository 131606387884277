import clsx from 'clsx'

import InavoLoading from '@/components/InavoLoading/InavoLoading'
import LoadingDots from '@/components/LoadingDots/LoadingDots'

import './primaryButton.scss'

export const LOADING_TYPES = {
  DEFAULT: 'default',
  DOTS: 'dots',
}

const PrimaryButton = ({ 
  children, 
  className, 
  disable, 
  isLoading, 
  loadingType = LOADING_TYPES.DEFAULT,
  ...otherProps 
}) => {
  const allClassnames = clsx('primary-button', className, {
    'primary-button--disable': disable || isLoading,
  })

  const renderLoading = () => {
    switch (loadingType) {
      case LOADING_TYPES.DOTS:
        return <LoadingDots />
      case LOADING_TYPES.DEFAULT:
      default:
        return <InavoLoading className='primary-button--loading' />
    }
  }

  return (
    <button className={allClassnames} {...otherProps}>
      {isLoading ? renderLoading() : children}
    </button>
  )
}

export default PrimaryButton
