import i18next from 'i18next'
import { v4 as uuid } from 'uuid'

import { DEFAULT_VALUES as ToggleSwitchDefaultValues } from '@/components/FormFieldComponents/ToggleSwitch/ToggleSwitch'
import {
  ADVANCED_FIELD_TYPE_VALUES,
  API_METHODS,
  DATE_UTC_FORMAT,
  FIELD_GROUP_TYPES,
  FIELD_SCOPE_TYPES,
  FIELD_TYPES,
  GENERAL_FIELD_TYPE_VALUES,
} from '@/constants'

export const FIELD_DESCRIPTION_TYPES = {
  EXTERNAL: 'external',
  HINT: 'hint',
}
export const fieldDescriptionPropertySchema = {
  text: '',
  type: FIELD_DESCRIPTION_TYPES.EXTERNAL,
  isEnabled: false,
}

export const FILE_CUSTOM_FORMAT_TYPES = {
  ARRAY: 'array',
  PURE: 'pure',
}
export const FIELD_CUSTOM_PROPERTIES = {
  [FIELD_TYPES.DATE]: {
    customInputFormat: DATE_UTC_FORMAT,
    customOutputFormat: DATE_UTC_FORMAT,
    isActiveHourStatus: false,
  },
  [FIELD_TYPES.FILE]: {
    onlyAccepts: [],
    isMultiple: false,
    customInputFormatType: FILE_CUSTOM_FORMAT_TYPES.PURE,
    customOutputFormatType: FILE_CUSTOM_FORMAT_TYPES.PURE,
  },
  [FIELD_TYPES.REMOTE_SELECT]: {
    url: '',
    header: {},
    method: API_METHODS.GET,
    dataKey: '',
    labelKey: '',
    valueKey: '',
    dependencyKeys: [],
    searchQueryParameter: '',
  },
  [FIELD_TYPES.IMAGE]: {
    isEnabledEdit: false,
  },
}

const FIELD_DEFAULT_PROPERTIES = {
  invisible: false,
  isDependency: false,
  rules: [],
  isNotIncluded: false,
  notIncludedConditions: [], // If there are not any conditions, the field is not included as default.
  description: fieldDescriptionPropertySchema,
}

const getDefaultValue = type => {
  switch (type) {
    case FIELD_TYPES.BEAUTIFY_JSON:
      return {}
    case FIELD_TYPES.TOGGLE_SWITCH:
      return ToggleSwitchDefaultValues.OFF
    default:
      return ''
  }
}

const getFieldScopeType = type => {
  if (ADVANCED_FIELD_TYPE_VALUES.includes(type)) {
    return FIELD_SCOPE_TYPES.GENERAL
  }

  return FIELD_SCOPE_TYPES.BODY
}

export const generateRandomFieldKey = () => `console_${Math.random().toString(36).substring(2, 15)}`

export const generateFieldId = () => uuid()

const getDefaultFieldObject = itemType => ({
  type: itemType,
  key: '',
  label: '',
  id: generateFieldId(),
  defaultValue: getDefaultValue(itemType),
  fieldScopeType: getFieldScopeType(itemType),
})
const getFieldPropertiesObject = (itemType, { customPropertiesObject }) => {
  // Some properties can be not included default properties due it is added after time.
  const defaultProperties = {
    ...FIELD_DEFAULT_PROPERTIES,
    ...customPropertiesObject,
  }

  switch (itemType) {
    case FIELD_TYPES.SELECT:
      return {
        ...defaultProperties,
        choices: [],
      }
    case FIELD_TYPES.REMOTE_SELECT:
      return {
        ...defaultProperties,
        ...FIELD_CUSTOM_PROPERTIES[FIELD_TYPES.REMOTE_SELECT],
      }
    case FIELD_TYPES.TOGGLE_SWITCH:
      return {
        ...defaultProperties,
        onValue: ToggleSwitchDefaultValues.ON,
        offValue: ToggleSwitchDefaultValues.OFF,
      }
    case FIELD_TYPES.FILE:
      return {
        ...defaultProperties,
        ...FIELD_CUSTOM_PROPERTIES[FIELD_TYPES.FILE],
      }
    case FIELD_TYPES.EDIT_TABLE:
      return {
        ...defaultProperties,
        table: {
          label: '',
          url: '',
          header: {},
          method: API_METHODS.GET,
          dataKey: '',
          limitKey: '',
          totalKey: '',
          properties: {},
        },
        columns: [],
        actions: [],
        filters: [], // It is not included in old fields.
      }
    case FIELD_TYPES.DATE:
      return {
        ...defaultProperties,
        ...FIELD_CUSTOM_PROPERTIES[FIELD_TYPES.DATE],
      }
    case FIELD_TYPES.IMAGE:
      return {
        ...defaultProperties,
        ...FIELD_CUSTOM_PROPERTIES[FIELD_TYPES.IMAGE],
      }
    default:
      return { ...defaultProperties }
  }
}

// Some properties features can be closed by the field type. The rest of that are default features for all fields. Keep your mind that.
export const getFieldObject = payload => {
  const { type, customFieldObject = {}, customPropertiesObject = {} } = payload

  const defaultObject = getDefaultFieldObject(type)
  const properties = getFieldPropertiesObject(type, {
    customPropertiesObject,
  })

  return {
    ...defaultObject,
    properties,
    ...customFieldObject,
  }
}

export const getFieldText = fieldType => {
  switch (fieldType) {
    case FIELD_TYPES.STRING:
      return i18next.t('buildApi.field.type.string')
    case FIELD_TYPES.TEXTAREA:
      return i18next.t('buildApi.field.type.textarea')
    case FIELD_TYPES.NUMBER:
      return i18next.t('buildApi.field.type.number')
    case FIELD_TYPES.PASSWORD:
      return i18next.t('buildApi.field.type.password')
    case FIELD_TYPES.SELECT:
      return i18next.t('buildApi.field.type.select')
    case FIELD_TYPES.REMOTE_SELECT:
      return i18next.t('buildApi.field.type.remoteSelect')
    case FIELD_TYPES.IMAGE:
      return i18next.t('buildApi.field.type.image')
    case FIELD_TYPES.RICH_TEXT:
      return i18next.t('buildApi.field.type.richText')
    case FIELD_TYPES.TOGGLE_SWITCH:
      return i18next.t('buildApi.field.type.toggleSwitch')
    case FIELD_TYPES.DATE:
      return i18next.t('buildApi.field.type.date')
    case FIELD_TYPES.HOUR:
      return i18next.t('buildApi.field.type.hour')
    case FIELD_TYPES.FILE:
      return i18next.t('buildApi.field.type.file')
    case FIELD_TYPES.COLOR_PICKER:
      return i18next.t('buildApi.field.type.colorPicker')
    case FIELD_TYPES.BEAUTIFY_JSON:
      return i18next.t('buildApi.field.type.beautifyJson')
    case FIELD_TYPES.EDIT_TABLE:
      return i18next.t('buildApi.field.type.editTable')
    default:
      return i18next.t('buildApi.field.type.unknown')
  }
}

const getApiCommonObject = () => ({
  method: '',
  url: '',
  description: '',
  fields: [],
  header: {},
  defaultBody: {},
  otherFields: {},
  defaultUrlQueryParams: {},
  configs: {},
})
export const getApiObject = payload => ({
  ...getApiCommonObject(),
  title: '',
  apiGroupId: null,
  ...payload,
})

export const getInitialValuesFormApiObject = () => ({
  ...getApiCommonObject(),
  initialValuesMappings: [],
})

export const getFieldValuesByGroupType = groupType => {
  switch (groupType) {
    case FIELD_GROUP_TYPES.GENERAL:
      return GENERAL_FIELD_TYPE_VALUES
    case FIELD_GROUP_TYPES.ADVANCED:
      return ADVANCED_FIELD_TYPE_VALUES
  }
}

export const getClonedField = field => ({
  ...field,
  id: generateFieldId(),
  key: generateRandomFieldKey(),
})
