import './loadingDots.scss'

const LoadingDots = () => {
  return (
    <div className='loading-dots'>
      <div className='loading-dots__dot'></div>
      <div className='loading-dots__dot'></div>
      <div className='loading-dots__dot'></div>
    </div>
  )
}

export default LoadingDots
