// We should add it
// eslint-disable-next-line no-unused-vars
import ApexCharts from 'apexcharts'
import dayjs from 'dayjs'
import 'dayjs/locale/tr'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjsUtc from 'dayjs/plugin/utc'
import i18n from 'i18next'
import { Suspense, useEffect } from 'react'
import { initReactI18next } from 'react-i18next'
import { RouterProvider } from 'react-router-dom'

import OfflinePage from '@/components/OfflinePage/OfflinePage'
import SuspenseLoading from '@/components/SuspenseLoading'
import { LANGUAGES } from '@/constants'
import useOnlineStatus from '@/hooks/useOnlineStatus'
import languages from '@/languages'
import router from '@/router'
import { getActiveProjectLanguage, isMobileDevice as isMobileDeviceUtil } from '@/utils'
import { getActiveProjectId } from '@/utils/localStorageActions'
import { getSessionActiveProjectId, setSessionActiveProjectId } from '@/utils/sessionStorageActions'

const SuspenseFallback = () => {
  return <SuspenseLoading />
}

function App() {
  const { isOnline } = useOnlineStatus()

  const activeLanguage = getActiveProjectLanguage()

  const handleRedirectToMobileApp = () => {
    const isMobileDevice = isMobileDeviceUtil()
    if (isMobileDevice) {
      const deepLink = `net.inavo.app:/${window.location.pathname}`
      window.location.href = deepLink
    }
  }

  const handleSetInitialDayjsSettings = () => {
    dayjs.extend(customParseFormat)
    dayjs.extend(dayjsUtc)
    dayjs.locale(activeLanguage)
  }

  const handleSetInitialI18nSettings = () => {
    i18n.use(initReactI18next).init({
      resources: languages,
      interpolation: { escapeValue: false },
      lng: activeLanguage,
      whitelist: Object.values(LANGUAGES),
    })
  }

  const handleSetActiveProjectIdToSessionStorage = () => {
    const sessionActiveProjectId = getSessionActiveProjectId()
    const activeProjectId = getActiveProjectId()

    if (!sessionActiveProjectId && activeProjectId) setSessionActiveProjectId(activeProjectId)
  }

  useEffect(() => {
    handleSetInitialDayjsSettings()
    handleSetInitialI18nSettings()

    handleRedirectToMobileApp()

    handleSetActiveProjectIdToSessionStorage()
  }, [])

  return (
    <Suspense fallback={<SuspenseFallback />}>
      {!isOnline && <OfflinePage />}
      <RouterProvider router={router} />
    </Suspense>
  )
}

export default App
